var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-box"},[_c('el-table',{key:1,ref:"multipleTable",attrs:{"data":_vm.tableData,"height":"100%","header-cell-style":{
            background: '#FAFAFA',
            color: '#333333',
            'font-size': '14px',
            'font-weight': '600',
        }}},[_c('el-table-column',{attrs:{"label":"创建时间","width":"93"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.createTime ? scope.row.createTime.split(' ')[0] : '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"订单编号","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.contractStatus == 3)?_c('div',{style:({
                        color: 'red',
                        cursor: 'pointer',
                    })},[_c('el-button',{staticClass:"iconfont icon-fuzhi1",staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.onCopy(scope.row)}}}),_vm._v(" "+_vm._s(scope.row.orderNo || '- -')+" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"该订单的关联合同已作废","placement":"bottom"}},[(scope.row.contractStatus == 3)?_c('i',{staticClass:"el-icon-warning",staticStyle:{"color":"#ff912a"}}):_vm._e()])],1):_c('div',{style:({
                        color: scope.row.sourceType == 1 ? '#2370eb' : '#2370eb',
                        cursor: 'pointer',
                    })},[_c('el-button',{staticClass:"iconfont icon-fuzhi1",staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.onCopy(scope.row)}}}),_c('span',{on:{"click":function($event){return _vm.onDetail(scope.row)}}},[_vm._v(_vm._s(scope.row.orderNo || '- -')+" ")]),(scope.row.sourceType == 2)?_c('i',{staticClass:"iconfont icon-bianji",on:{"click":function($event){return _vm.onEditAmount(scope.row)}}}):_vm._e()],1)]}}])},[_c('template',{slot:"header"},[_vm._v("      订单编号 ")])],2),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"部门","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_c('el-tooltip',{attrs:{"placement":"top"}},[_vm._v("· "),_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((scope.row
                                    .departmentAdminDOList),function(item){return _c('p',{key:item.id,staticStyle:{"line-height":"2"}},[_vm._v(" "+_vm._s(item.departmentName)+" ")])}),0),_c('em',{staticStyle:{"font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.returnName(scope.row))+" ")])])],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"员工","width":"55"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.adminName || '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"客户名称","width":"239","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.companyName || '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"订单状态","width":"150","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"order_status",style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.status == 1 ? '未交付' : scope.row.status == 2 ? '部分交付' : scope.row.status == 3 ? '已交付' : '- -')+" "),_c('span',{staticClass:"set_btn",on:{"click":function($event){return _vm.onSetUp(scope.row)}}},[_vm._v("设置交付状态")]),(scope.row.status != 1)?_c('span',{staticClass:"no_btn",on:{"click":function($event){return _vm.onRelation(scope.row)}}},[_vm._v("查看")]):(
                            (scope.row.reminderStatus == 1 ||
                                (scope.row.reminderStatus == 2 &&
                                    !scope.row.countDown)) &&
                            !_vm.btnP.channelorder
                        )?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onReminder(scope.row)}}},[_vm._v("催单")]):(
                            scope.row.reminderStatus == 2 &&
                            scope.row.countDown &&
                            !_vm.btnP.channelorder
                        )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(scope.row.countDown)+" ")]),_c('span',{staticClass:"no_btn",staticStyle:{"border":"1px solid #46a136","color":"#46a136","height":"20px","line-height":"20px"}},[_vm._v(" 已催单")])]):(
                            scope.row.reminderStatus == 2 &&
                            scope.row.countDown &&
                            _vm.btnP.channelorder
                        )?_c('span',[_c('i',{staticClass:"iconfont icon-xingzhuang no_btn",staticStyle:{"color":"#d0021b","right":"45px"}}),_c('span',{staticClass:"no_btn",staticStyle:{"border":"1px solid #d0021b","color":"#d0021b","height":"20px","line-height":"20px"}},[_vm._v("催单中")])]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"产品名称","width":"100","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.productAttributeRelationBOList && scope.row.productAttributeRelationBOList.length > 0 ? scope.row.productAttributeRelationBOList[0] .productName : '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"购买类型","width":"80","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(_vm.returnOrderType(scope.row))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"用户数","width":"80","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.orderNumber || 0)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"年限","width":"80","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.orderTerm || 0)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),(_vm.btnP.receivable)?_c('el-table-column',{attrs:{"label":"应收金额","show-overflow-tooltip":"","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.receivableAmount ? scope.row.receivableAmount : '- -')+" ")])]}}],null,false,1495002844)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"20"}}),(_vm.btnP.receivable)?_c('el-table-column',{attrs:{"label":"实收金额","show-overflow-tooltip":"","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                        color: scope.row.contractStatus == 3 ? 'red' : '',
                    })},[_vm._v(" "+_vm._s(scope.row.netReceiptsAmount ? scope.row.netReceiptsAmount : '- -')+" ")])]}}],null,false,2351164604)}):_vm._e(),_c('el-table-column',{attrs:{"label":"操作","show-overflow-tooltip":"","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        scope.row.status != 3 &&
                        scope.row.contractStatus != 3
                    )?_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onPay(scope.row)}}},[_vm._v(" 交付 ")]):_vm._e()]}}])}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1),_c('el-dialog',{attrs:{"visible":_vm.dialogRelation,"width":"780px","before-close":_vm.handleClose,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogRelation=$event}}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","color":"#000000","font-size":"16px","font-weight":"500"}},[_vm._v(" 关联交付订单 ")])]),_c('Relation',{ref:"relation",on:{"close":_vm.handleClose}})],2),_c('el-dialog',{attrs:{"visible":_vm.dialogSetUp,"width":"560px","before-close":_vm.handleClose,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogSetUp=$event}}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","color":"#000000","font-size":"16px","font-weight":"500"}},[_vm._v(" 设置订单状态 ")])]),_c('SetUp',{ref:"setUp",on:{"close":_vm.handleClose}})],2),_c('el-dialog',{attrs:{"visible":_vm.dialogEdit,"width":"560px","before-close":_vm.handleClose,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogEdit=$event}}},[_c('Edit',{ref:"editAli",on:{"close":_vm.handleClose}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }